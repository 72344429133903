export default {
  employeeTable: {
      selected: [],
      search: '',
      headers: [
          {
              text: 'Name',
              align: 'start',
              sortable: true,
              value: 'name',
          },
          { text: 'Company', value: 'company' },
          { text: 'City', value: 'city' },
          { text: 'State', value: 'state' },
          {
            text: '',
            sortable: false,
            value: 'actions'
          }
      ],
      employee: [
          {
              name: 'Joe James',
              company: 'Example Inc.',
              city: 'Yonkers',
              state: 'NY',
              select: false
          },
          {
              name: 'John Walsh',
              company: 'Example Inc.',
              city: 'Hartford',
              state: 'CT',
              select: false
          },
          {
              name: 'Bob Herm',
              company: 'Example Inc.',
              city: 'Tampa',
              state: 'FL',
              select: false

          },
          {
              name: 'James Houston',
              company: 'Example Inc.',
              city: 'Dallas',
              state: 'TX',
              select: false

          },
          {
              name: 'Prabhakar Linwood',
              company: 'Example Inc.',
              city: 'Hartford',
              state: 'CT',
              select: false
          },
          {
              name: 'Jelly bean',
              company: 'Example Inc.',
              city: 'Yonkers',
              state: 'NY',
              select: false
          },
          {
              name: 'Kaui Ignace',
              company: 'Example Inc.',
              city: 'Hartford',
              state: 'CT',
              select: false
          },
          {
              name: 'Esperanza Susanne',
              company: 'Example Inc.',
              city: 'Tampa',
              state: 'FL',
              select: false
          },
          {
              name: 'Christian Birgitte',
              company: 'Example Inc.',
              city: 'Hartford',
              state: 'CT',
              select: false
          },
          {
              name: 'Meral Elias',
              company: 'Example Inc.',
              city: 'Yonkers',
              state: 'NY',
              select: false
          },
          {
              name: 'Deep Pau',
              company: 'Example Inc.',
              city: 'Dallas',
              state: 'TX',
              select: false
          },
          {
              name: 'Sebastiana Hani',
              company: 'Example Inc.',
              city: 'Yonkers',
              state: 'NY',
              select: false
          },
          {
              name: 'Marciano Oihana',
              company: 'Example Inc.',
              city: 'Yonkers',
              state: 'TX',
              select: false
          },
          {
              name: 'Brigid Ankur',
              company: 'Example Inc.',
              city: 'Dallas',
              state: 'NY',
              select: false
          },
          {
              name: 'Anna Siranush',
              company: 'Example Inc.',
              city: 'Hartford',
              state: 'CT',
              select: false
          },
          {
              name: 'Avram Sylva',
              company: 'Example Inc.',
              city: 'Tampa',
              state: 'FL',
              select: false
          },
          {
              name: 'Serafima Babatunde',
              company: 'Example Inc.',
              city: 'Tampa',
              state: 'FL',
              select: false
          },
          {
              name: 'Gaston Festus',
              company: 'Example Inc.',
              city: 'Yonkers',
              state: 'NY',
              select: false
          },
      ],
  },
  materialUITable: [
      {
          id: 0,
          name: 'Mark Otto',
          email: 'ottoto@wxample.com',
          product: 'ON the Road',
          price: '$25 224.2',
          date: '11 May 2017',
          city: 'Otsego',
          status: 'Sent',
      },
      {
          id: 1,
          name: 'Jacob Thornton',
          email: 'thornton@wxample.com',
          product: 'HP Core i7',
          price: '$1 254.2',
          date: '4 Jun 2017',
          city: 'Fivepointville',
          status: 'Sent',
      },
      {
          id: 2,
          name: 'Larry the Bird',
          email: 'bird@wxample.com',
          product: 'Air Pro',
          price: '$1 570.0',
          date: '27 Aug 2017',
          city: 'Leadville North',
          status: 'Pending',
      },
      {
          id: 3,
          name: 'Joseph May',
          email: 'josephmay@wxample.com',
          product: 'Version Control',
          price: '$5 224.5',
          date: '19 Feb 2018',
          city: 'Seaforth',
          status: 'Declined',
      },
      {
          id: 4,
          name: 'Peter Horadnia',
          email: 'horadnia@wxample.com',
          product: 'Let\'s Dance',
          price: '$43 594.7',
          date: '1 Mar 2018',
          city: 'Hanoverton',
          status: 'Sent',
      },
  ],
  menu: [
      'Edit',
      'Copy',
      'Delete',
      'Print'
  ]
}