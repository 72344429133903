<template>
  <div>
    <DataTable
      title="Employee Table"
      :pagination="{ pageNo: 1, totalNoPages: 1, limit: 10, skip: 0 }"
      :headers="mock.employeeTable.headers"
      :items="mock.employeeTable.employee"
    ></DataTable>
  </div>
</template>

<script>
import mock from './mock'
import DataTable from '../components/base/DataTable'
export default {
  name: 'Tables',
  data () {
    return {
      mock
    }
  },
  components: {
    DataTable
  },
  methods: {
    checkItem (item) {
      console.log(item)
      return '123'
    }
  }
}
</script>
